<template>
  <div>
    <v-content>
      <nav class="navbar is-transparent">
        <div class="navbar-brand">
          <router-link to="/">
            <div class="header__Logo">
              <div class="header__Logo-element">
                <img src="../../../../assets/logo_profitfamily.png" alt="" />
              </div>
            </div>
          </router-link>
          <div
            role="button"
            class="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            @click="isOpen = !isOpen"
            v-bind:class="{ 'is-active': isOpen }"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div class="navbar-menu" v-if="!isLoggedIn">
          <div class="navbar-end">
            <div class="navbar-item">
              <div class="field is-grouped is-pulled-right">
                <v-btn @click="loginPage()" outlined>Войти</v-btn>
              </div>
            </div>
          </div>
        </div>

        <div class="navbar-menu" v-bind:class="{ 'is-active': isOpen }" v-if="isLoggedIn">
          <div class="navbar-end" v-if="!isAdmin">
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                Приложения
              </a>
              <div class="navbar-dropdown">
                <router-link class="navbar-item" to="/applications">
                  Список лендингов
                </router-link>
                <router-link class="navbar-item" to="/templates">
                  Список шаблонов
                </router-link>
                <router-link class="navbar-item" to="/offers">
                  Список офферов Keitaro
                </router-link>
                <router-link class="navbar-item" to="/tokens">
                  Список аккаунтов VK
                </router-link>
              </div>
            </div>
<!--            <router-link class="navbar-item" to="/profiles">-->
<!--              Пользователи-->
<!--            </router-link>-->
<!--            <router-link class="navbar-item" to="/statistics">-->
<!--              Статистика-->
<!--            </router-link>-->
            <router-link class="navbar-item" to="/tasks">
              Задачи
            </router-link>
            <router-link class="navbar-item" to="/profile">
              Профиль
            </router-link>
          </div>

          <div class="navbar-end" v-if="isAdmin">
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                Приложения
              </a>
              <div class="navbar-dropdown">
<!--                <router-link class="navbar-item" to="/servers">-->
<!--                  Список серверов-->
<!--                </router-link>-->
                <router-link class="navbar-item" to="/applications">
                  Список лендингов
                </router-link>
                <router-link class="navbar-item" to="/templates">
                  Список шаблонов
                </router-link>
                <router-link class="navbar-item" to="/offers">
                  Список офферов Keitaro
                </router-link>
                <router-link class="navbar-item" to="/tokens">
                  Список аккаунтов VK
                </router-link>
                <router-link class="navbar-item" to="/resales">
                  Список партнеров
                </router-link>
              </div>
            </div>
            <router-link class="navbar-item" to="/profiles">
              Пользователи
            </router-link>
<!--            <router-link class="navbar-item" to="/statistics">-->
<!--              Статистика-->
<!--            </router-link>-->
            <router-link class="navbar-item" to="/tasks">
              Задачи
            </router-link>
            <router-link class="navbar-item" to="/profile">
              Профиль
            </router-link>
          </div>

          <div class="navbar-item">
            <div class="field is-grouped is-pulled-right">
              <v-btn @click="logout()" depressed color="primary">
                Выход
                <v-icon right dark>mdi-logout</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="navbar-item parent">
          <my-toggle></my-toggle>
        </div>
      </nav>
    </v-content>
  </div>
</template>

<script>
import Toggle from '../app-toggle/Toggle.vue';
export default {
  name: "Header",
  data() {
    return {
      isOpen: false,
    };
  },
  components: {
    'my-toggle': Toggle,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.IS_LOGGED_IN;
    },
    isAdmin() {
      return this.$store.getters.IS_ADMIN;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT").then(() => {
        this.$router.push("/login");
      });
    },
    loginPage() {
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style>
.navbar {
  border-bottom: solid 1px #e4e4e4;
}

/* .navbar-menu a {
  color: #585858 !important;
} */

.navbar-menu a:hover {
  color: #2196f3 !important;
}

.header__Logo-element img {
  max-height: 3.75rem;
}

.header__Logo {
  margin-left: 20px;
  padding: 5px;
}

.header__Logo-element {
  display: inline-block;
  line-height: 22px;
  vertical-align: middle;
}

.header__Logo-element-text {
  margin-left: 5px;
}

.header__Logo-text {
  display: block;
  text-align: left;
  font-weight: 400;
  padding: 0;
  margin: 0;
  text-decoration: none;
  text-underline: none;
  color: #4a4a4a;
}

.header__Logo-text-bold {
  font-size: 30px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.header__Logo-text-thin {
  color: #292929;
}

@media (max-width: 600px) {
  .header__Logo-element img {
    max-height: 2.75rem;
  }

  .header__Logo-text-thin {
    font-size: 14px !important;
  }

  .header__Logo-text-bold {
    font-size: 24px !important;
  }

  .navbar-brand {
    margin-top: 5px;
  }
}
</style>
